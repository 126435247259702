import { getBalance } from '~/entities/account'

interface State {
  /**
   * Монеты, которые пополняются каждый месяц и которые служат только для передачи другим пользователям. Их нельзя использовать в магазине
   */
  active: number
  /**
   * Монеты, которые передали пользователю - их уже можно использовать в магазине
   */
  usable: number
}

export const useBalance = defineStore('balance', {
  state: (): State => ({
    active: 0,
    usable: 0
  }),
  getters: {
    getActive: (state) => state.active,
    getUsable: (state) => state.usable
  },
  actions: {
    async fetchBalance() {
      try {
        const { active, usable } = await getBalance()
        this.active = active
        this.usable = usable
        return { active, usable }
      } catch (e) {
        console.log(e)
      }
    },
    isBalanceEnough(amount: number) {
      return this.usable >= amount
    }
  }
})
