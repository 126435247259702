import { useCorpFetcher } from '~/shared/lib'

import { type AccountBalancesOut } from '~/entities/account'

export const getBalance = async () => {
  const { data } = await useCorpFetcher<AccountBalancesOut>({
    endpoint: '/account/balance',
    method: 'GET'
  })
  return data
}
